import * as Sentry from '@sentry/react';
import { readCookie, writeCookie } from '../cookie';
import { sha256 } from '../crypto';
import { get } from '../http';
import { parseAbsoluteUtcDate } from '../date';
import dateFnsFormat from 'date-fns/format';

interface ICartItem {
    item: number;
    quantity: number;
    price: number;
}

export class EmarsysTracker {
    private readonly HERM_ID: string = '_herm_uid';
    private readonly EMARSYS_CID: string = '_emarsys_cid';
    private readonly EMARSYS_CUSTOMER_PARAM: string = 'sc_customer';

    private readonly COOKIE_MAX_AGE: number = 365;
    private eventsQueue: any[] = [];
    private queue: any[];

    constructor() {
        this.queue = typeof window !== 'undefined' ? (window as any).ScarabQueue : [];
    }

    trackView(itemId: number) {
        this.eventsQueue.push(['view', `${itemId}`]);
    }

    trackGenericPageView(pageTitle: string, deviceType: string) {
        if (typeof window === 'undefined') {
            return;
        }

        this.trackTags('page_view', {
            page_title: pageTitle,
            device_type: deviceType === 'mobile' ? 'mobile' : 'desktop',
            url: window.location.href,
            referrer: document.referrer,
        });
    }

    trackCustomSearchView(store) {
        const { destination, cruiseline, departure, arrival } = this.getParamsFromSearch(store);
        this.trackTags('search', {
            destination,
            cruiseline,
            departure_date: departure,
            arrival_date: arrival,
        });
    }

    trackAddToCart(cartItemList: ICartItem[]) {
        this.eventsQueue.push(['cart', cartItemList]);
        this.pushEmarsysEvents();
    }

    trackTags(tagName: string, tagObject: Record<string, any>) {
        this.eventsQueue.push(['tag', tagName, tagObject]);
    }

    trackNonSearchPage(url: string, pathName?: string): void {
        if (pathName) {
            this.eventsQueue.push(['category', pathName]);
        } else {
            if (url.charAt(0) === '/') {
                url = url.substring(1);
            }
            url = url.replace(/-/g, '_');
            const category: string = url.replace(/\//g, '>');
            this.eventsQueue.push(['category', category]);
        }
    }

    // User identification
    async identifyCustomer(apiHost: string, email?: string) {
        const emailFromCookie = this.getCustomerEmarsysId();
        const urlParams = new URLSearchParams(window.location.search);
        const emarsysParamEmail = urlParams.get(this.EMARSYS_CUSTOMER_PARAM);
        const hashedEmail = await this.getHashedEmail(email);

        const identificationEmail = hashedEmail || emarsysParamEmail || emailFromCookie;

        this.setCustomerId(identificationEmail);
        this.setEmarsysCustomerIdCookie(identificationEmail);

        this.pushEmarsysEvents();
    }

    trackSearchPage(store: any) {
        const trackingString: string = this.buildTrackingCategoryString(store);
        this.trackCustomSearchView(store);
        if (trackingString && this.queue) {
            this.queue.push(['category', trackingString.toLowerCase()]);
        }
    }

    /**
     * Sends the tracking events to Emarsys
     */
    pushEmarsysEvents(): void {
        if (this.eventsQueue.length > 0 && this.queue) {
            this.eventsQueue.forEach((event) => this.queue.push(event));
            this.eventsQueue = [];
            this.queue.push(['go']);
        }
    }

    formatSearchFilter(filterString: string): string {
        filterString = filterString.replace(/\((.*?)\)/gm, '');

        return filterString
            .trim()
            .replace(/[\s,-]+/g, '_')
            .toLowerCase();
    }

    private buildTrackingCategoryString(store) {
        try {
            const { destination, cruiseline, shipName } = this.getParamsFromSearch(store);

            const trackingString = [destination, cruiseline, shipName]
                .filter((x) => x !== '')
                .map((x) => this.formatSearchFilter(x))
                .join('>');

            return trackingString;
        } catch (error) {
            Sentry.captureException(error, {
                extra: { customErr: 'Error tracking Emarsys category' },
            });
        }
    }

    private getElement(array) {
        if (Array.isArray(array)) {
            return array.slice(0);
        } else {
            return;
        }
    }

    private getShip(search) {
        const ship = search.searchParams.filterParams?.ship;
        return this.getElement(ship);
    }

    private getCompany(search) {
        const company = search.searchParams.filterParams?.company;
        return this.getElement(company);
    }
    private getArrival(search) {
        const arrivalDate = search.searchParams.filterParams?.dateMin;
        if (!arrivalDate) {
            return null;
        }
        const utcDate = parseAbsoluteUtcDate(arrivalDate * 1000);

        return dateFnsFormat(utcDate, 'dd-MM-yyyy');
    }

    private getDeparture(search) {
        const departureDate = search.searchParams.filterParams?.dateMax;
        if (!departureDate) {
            return null;
        }
        const utcDate = parseAbsoluteUtcDate(departureDate * 1000);

        return dateFnsFormat(utcDate, 'dd-MM-yyyy');
    }

    private getZone(search) {
        const zone = search.searchParams.filterParams?.zone;
        return this.getElement(zone);
    }

    private setEmarsysCustomerIdCookie(hashedEmail: string) {
        writeCookie(this.EMARSYS_CID, hashedEmail, this.COOKIE_MAX_AGE);
    }

    setCustomerId(hashedEmail: string) {
        if (!hashedEmail) {
            return;
        }
        this.eventsQueue.push(['setCustomerId', hashedEmail]);
    }

    private async getHashedEmail(email: string) {
        if (!email) {
            return;
        }
        const shaObj = await sha256(email);
        return shaObj;
    }

    private getCustomerHermId() {
        return readCookie(this.HERM_ID);
    }

    private getCustomerEmarsysId() {
        return readCookie(this.EMARSYS_CID);
    }

    private getParamsFromSearch(store) {
        const search = store.search;
        const companies = search.searchFacets.companyNids;
        const zones = search.searchFacets.zoneNids;

        const company = Number(this.getCompany(search));
        const zone = Number(this.getZone(search));
        const arrival = this.getArrival(search);
        const departure = this.getDeparture(search);
        const ships = search.searchFacets.shipNids;

        const cruiseline = company
            ? companies.find((companyDetails) => companyDetails.id === company)?.title || ''
            : '';

        const destination = zones?.find((zoneDetails) => zoneDetails.id === zone)?.title || '';
        const ship = Number(this.getShip(search));

        const shipName = ship
            ? ships.filter((shipDetails) => shipDetails.id === ship).reduce((x) => x).title
            : '';

        return { cruiseline, arrival, departure, destination, shipName };
    }
}

export const emarsysTracker = new EmarsysTracker();
